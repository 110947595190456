import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/home";
import AboutUs from "./components/aboutus";
import ContactUs from "./components/contact";
import Quote from "./components/quote";
import Careers from "./components/careers";
import Products from "./components/product";
import ProductDetail from "./components/ProductDetail";

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// Services;
import Webandsoft from "./components/services/websoftdev";
import Appandsoft from "./components/services/mobileappdev";
import CyberIT from "./components/services/cyberit";
import Cloudcomp from "./components/services/cloudcomputing";
import Oracletech from "./components/services/oracleEBStechnical";
import BusinessIntelligence from "./components/services/BusinessIntelligence";
import Identityaccess from "./components/services/identity_access";
import Oracle from "./components/services/oracle";
import ProjectManagement from "./components/services/projectmanagement";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Start the progress bar
    NProgress.start();

    // Simulate content loading, replace this with actual data loading or page loading logic
    const timer = setTimeout(() => {
      // Stop the progress bar after content has loaded
      NProgress.done();
    }, 1000);

    // Cleanup function to clear the timer
    return () => {
      clearTimeout(timer);
      NProgress.done(); // Ensure progress bar stops even if the component is unmounted
    };
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/request-a-demo" element={<Quote />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product/:name" element={<ProductDetail />} />

          {/* Services */}
          <Route
            path="/services/web-and-software-development"
            element={<Webandsoft />}
          />
          <Route
            path="/services/mobile-applications-development"
            element={<Appandsoft />}
          />
          <Route path="/services/cyber-and-it" element={<CyberIT />} />
          <Route path="/services/cloud-computing" element={<Cloudcomp />} />
          <Route
            path="/services/oracle-ebs-technical"
            element={<Oracletech />}
          />
          <Route
            path="/services/business-intelligence"
            element={<BusinessIntelligence />}
          />
          <Route
            path="/services/identity-access"
            element={<Identityaccess />}
          />
          <Route
            path="/services/oracle-ebs-implementation-and-extensions-services"
            element={<Oracle />}
          />
          <Route
            path="/services/project-management"
            element={<ProjectManagement />}
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
