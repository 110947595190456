import React from "react";
import MainHeader from "../../components/Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const MobileAppDevelopment = () => {
  return (
    <MainHeader>
      <div className="container-fluid p-0 m-0">
        <div className="container pt-5 pb-5">
          <div className="row">
            {/* Left Section */}
            <div className="col-md-7 d-flex flex-column" id="oracle_left_div" data-aos="fade-right">
              <p id="service_heading">Mobile Applications Development</p>
              <p id="services_para">
                Mobile Applications Development services create innovative and
                user-friendly applications tailored to your organization’s
                needs. We provide end-to-end development, from concept and
                design to deployment and ongoing support, ensuring your mobile
                solutions are effective and engaging for users.
              </p>
              <a href="#" id="custom_quote_btn">
                Custom Quote
              </a>
              <p className="mt-4" id="or_call_us_btn">
                  or Call Us:{" "}
                  <a href="tel:+923345592388" className="">
                  +92 334 5592388
                  </a>
                </p>
            </div>

            {/* Right Section */}
            <div className="col-md-5" data-aos="fade-left">
              <Servicecontactform></Servicecontactform>
            </div>
          </div>
        </div>
        <video
          autoPlay
          preload="none"
          poster="/img/other-pages-poster.png"
          loop
          muted
          className="background-video position-absolute top-0 start-0 w-100 h-100"
          style={{
            objectFit: "cover",
            zIndex: -1,
            filter: "brightness(80%)",
          }}
        >
          <source src="/video/Main-heading.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Description Section */}
        <div className="container mt-5">
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-md-5" id="oracle_description_image_div" data-aos="fade-right">
              <img src="/img/services/app_dev_img.jpg" alt="" />
            </div>
            <div className="col-md-7"  data-aos="fade-left">
              <p id="oracle_description_heading">
                Your Vision, Our Expertise: Crafting Stunning Mobile Experiences
              </p>
              <p id="oracle_description_para">
                Mobile application development refers to the process of creating
                software applications specifically designed to run on mobile
                devices such as smartphones and tablets. It involves designing,
                coding, testing, and deploying mobile applications to provide
                users with various functionalities and services on their mobile
                devices.
              </p>
              <p id="oracle_description_para1">
                <span>Platform Selection: -</span> Developers choose the target
                platforms for the application, such as iOS (Apple), Android
                (Google), or both. Each platform has its own development tools,
                programming languages (Swift/Objective-C for iOS, Java/Kotlin
                for Android), and design guidelines.
              </p>
              <p id="oracle_description_para1">
                <span>
                  User Interface (UI) and User Experience (UX) Design: -
                </span>{" "}
                Mobile applications require intuitive and visually appealing
                user interfaces to deliver a seamless user experience...
              </p>
              <p id="oracle_description_para1">
                <span>Front-End Development: -</span> Mobile app developers
                implement the UI design using programming languages specific to
                the target platforms...
              </p>
              <p id="oracle_description_para1">
                <span>Back-End Development: -</span> Mobile applications often
                require back-end development to handle server-side
                functionality, data storage, and communication with external
                APIs...
              </p>
              <p id="oracle_description_para1">
                <span>Mobile App Testing: -</span> Testing is a crucial step to
                ensure the functionality, performance, and usability of the
                mobile application...
              </p>
              <p id="oracle_description_para1">
                <span>App Store Deployment: -</span> Once the application is
                tested and ready for release, developers prepare the app for
                submission to the respective app stores...
              </p>
              <p id="oracle_description_para1">
                <span>Maintenance and Updates: -</span> Mobile application
                development involves ongoing maintenance and support...
              </p>
              <p id="oracle_description_para1">
                <span>Performance Optimization: -</span> Mobile applications
                should be optimized to provide a fast and responsive user
                experience...
              </p>
              <p id="oracle_description_para1">
                <span>Security and Data Privacy: -</span> Mobile app developers
                must implement security measures to protect user data and ensure
                data privacy...
              </p>
              <p id="oracle_description_para1">
                <span>User Engagement and Analytics: -</span> Mobile app
                developers often integrate analytics tools into applications to
                gather insights into user behavior...
              </p>
              <p id="oracle_description_para3">
                Mobile application development plays a significant role in
                delivering innovative and convenient experiences to users on
                their mobile devices...
              </p>
            </div>
          </div>
        </div>

        {/* FAQs Section */}
        <div className="container mt-5" data-aos="zoom-in">
          <p id="faq_heading">
            Frequently Asked Questions
          </p>
          <div
            className="accordion accordion-flush mt-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What platforms do you develop mobile applications for?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We develop mobile applications for various platforms,
                  including iOS, Android, and cross-platform solutions, ensuring
                  compatibility with a wide range of devices and user
                  preferences.
                </div>
              </div>
            </div>

            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  What is the mobile application development process?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our development process includes requirement gathering, UI/UX
                  design, development, testing, and deployment, followed by
                  ongoing support to ensure your app meets business objectives
                  and user needs.
                </div>
              </div>
            </div>

            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Can you integrate existing systems with the new mobile app?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, we can integrate your mobile application with existing
                  systems and databases, enabling seamless data exchange and
                  functionality that enhances the overall user experience.
                </div>
              </div>
            </div>

            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How do you ensure the quality and performance of the mobile
                  applications?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We conduct thorough testing throughout the development
                  process, including functional, performance, and usability
                  testing, to ensure high-quality applications that perform well
                  under various conditions.
                </div>
              </div>
            </div>

            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  What kind of ongoing support do you provide after the app
                  launch?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We offer ongoing support that includes maintenance, updates,
                  bug fixes, and performance monitoring to ensure your mobile
                  application continues to operate smoothly and meets evolving
                  user needs.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainHeader>
  );
};

export default MobileAppDevelopment;
