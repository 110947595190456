import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const CyberSecurity = () => {
  return (
    <MainHeader>
      <div className="container-fluid p-0 m-0" id="Oracle-form-banner">
        <div className="container pt-5 pb-5" >
          <div className="row">
            {/* Left Section */}
            <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
              <p id="service_heading">Cyber / IT Security</p>
              <p id="services_para">
                Cyber/IT Security services deliver robust protection for your
                organization’s digital assets. We provide risk assessments,
                threat monitoring, and ongoing support to ensure your systems
                and data remain secure and resilient against potential threats.
              </p>
              <a href="#" id="custom_quote_btn" className="">
                Custom Quote
              </a>
              <p className="mt-4" id="or_call_us_btn">
                or Call Us: <a href="tel:+923345592388">+92 334 5592388</a>
              </p>
            </div>

            {/* Right Section */}
            <div className="col-md-5" data-aos="fade-left">
              <Servicecontactform></Servicecontactform>
            </div>
          </div>
        </div>
      </div>
      <video
        autoPlay
        preload="none"
        poster="/img/other-pages-poster.png"
        loop
        muted
        className="background-video position-absolute top-0 start-0 w-100 h-100"
        style={{
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(80%)",
        }}
      >
        <source src="/video/Main-heading.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
            <img src="/img/services/cyber-img.jpg" alt="Cybersecurity Illustration" loading="lazy" />
          </div>
          <div className="col-md-7" data-aos="fade-left">
            <p id="oracle_description_heading" >
              Defend Your Digital Frontier: Expert Cybersecurity Solutions
            </p>

            <p id="oracle_description_para">
              Cybersecurity encompasses a range of practices, technologies, and
              measures designed to protect computer systems, networks, and data
              from unauthorized access, data breaches, and malicious attacks. It
              involves implementing strategies to prevent, detect, respond to,
              and recover from cyber threats to ensure the confidentiality,
              integrity, and availability of digital assets.
            </p>

            <p id="oracle_description_para1">
              <span>Risk Assessment and Management: -</span> Organizations
              conduct risk assessments to identify potential vulnerabilities,
              threats, and risks to their systems and data. This involves
              evaluating the likelihood and potential impact of cyber threats
              and developing risk management strategies to mitigate those risks.
            </p>

            <p id="oracle_description_para1">
              <span>Network Security: -</span> Network security focuses on
              protecting computer networks from unauthorized access and threats.
              This includes implementing firewalls, intrusion detection and
              prevention systems, secure configurations, and encryption to
              secure network communications.
            </p>

            <p id="oracle_description_para1">
              <span>Endpoint Security: -</span> Endpoint security involves
              securing individual devices (e.g., laptops, desktops, mobile
              devices) against cyber threats. This includes installing and
              updating antivirus software, endpoint protection tools, and
              implementing measures like device encryption and strong password
              policies.
            </p>

            <p id="oracle_description_para1">
              <span>Data Protection and Encryption: -</span> Data protection
              measures aim to safeguard sensitive and confidential information.
              Encryption techniques are used to secure data both in transit and
              at rest, ensuring that even if data is intercepted or stolen, it
              remains unreadable without the decryption key.
            </p>

            <p id="oracle_description_para1">
              <span>Access Control and Authentication: -</span> Access control
              mechanisms ensure that only authorized individuals can access
              systems, networks, and data. This involves implementing strong
              authentication methods, such as multi-factor authentication (MFA),
              role-based access controls (RBAC), and least privilege principles.
            </p>

            <p id="oracle_description_para1">
              <span>Security Awareness and Training: -</span> Cybersecurity
              awareness and training programs educate employees about best
              practices, security policies, and potential threats. This helps
              create a security-conscious culture within the organization and
              empowers individuals to detect and respond to cyber threats
              effectively.
            </p>

            <p id="oracle_description_para1">
              <span>Incident Response and Recovery: -</span> Incident response
              plans outline procedures for detecting, responding to, and
              recovering from cyber incidents. This includes timely incident
              detection, containment, eradication of threats, and restoring
              systems and data to normal operation. Regular testing and drills
              ensure that response plans are effective.
            </p>

            <p id="oracle_description_para1">
              <span>Security Monitoring and Threat Intelligence: -</span>{" "}
              Continuous monitoring of systems, networks, and applications helps
              identify potential security breaches or abnormal activities.
              Threat intelligence tools and services provide real-time
              information about emerging threats, vulnerabilities, and attack
              vectors, helping organizations proactively protect against
              potential cyber attacks.
            </p>

            <p id="oracle_description_para1">
              <span>Compliance and Regulations: -</span> Compliance with
              industry-specific regulations, such as GDPR or HIPAA, is crucial
              for organizations handling sensitive data. Compliance measures
              include implementing security controls, conducting audits, and
              adhering to privacy and data protection requirements.
            </p>

            <p id="oracle_description_para1">
              <span>Security Incident and Event Management (SIEM): -</span> SIEM
              systems aggregate and analyze security event logs from various
              sources to detect and respond to security incidents. They provide
              real-time visibility into the security posture of the organization
              and enable prompt incident response.
            </p>

            <p id="oracle_description_para3">
              Cybersecurity is a critical concern for organizations of all sizes
              and across industries. Implementing comprehensive cybersecurity
              practices helps protect against cyber threats, safeguard sensitive
              information, maintain business continuity, and build trust with
              customers and partners. By adopting a proactive approach to
              cybersecurity, organizations can minimize the risks associated
              with cyber attacks and ensure the long-term security of their
              digital assets.
            </p>
          </div>
        </div>

        {/* Oracle FAQs Section Start */}
        <div className="container mt-5" data-aos="zoom-in">
          <p id="faq_heading">
            Frequently Asked Questions
          </p>

          <div
            className="accordion accordion-flush mt-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is included in your Cyber/IT Security services?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our Cyber/IT Security services include risk assessments,
                  threat monitoring, incident response, vulnerability
                  management, and employee training to ensure comprehensive
                  protection for your organization.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How do you assess our current security posture?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We conduct thorough security assessments that include
                  vulnerability scans, penetration testing, and policy reviews
                  to identify weaknesses and recommend improvements tailored to
                  your organization's needs.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What measures do you take to protect sensitive data?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We implement multi-layered security measures such as
                  encryption, access controls, and regular security audits to
                  safeguard sensitive data from unauthorized access and
                  potential breaches.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How do you respond to security incidents?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our incident response process includes immediate containment,
                  investigation, remediation, and reporting to minimize impact
                  and prevent future occurrences, ensuring your organization can
                  recover swiftly.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  Do you provide ongoing security support and monitoring?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, we offer continuous monitoring and support services to
                  detect threats in real-time, along with regular updates and
                  maintenance to keep your security systems effective and up to
                  date.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainHeader>
  );
};

export default CyberSecurity;
