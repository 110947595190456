import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import WhatsAppButton from "../components/whatsappicon";

function Home() {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: false, // Whether the animation should happen only once
        });
    }, []);
    const [isVisible, setIsVisible] = useState(false);
    const [projectCount, setProjectCount] = useState(0);
    const [issueCount, setIssueCount] = useState(0);
    const [customerCount, setCustomerCount] = useState(0);
    const [InProgressCount, setInProgress] = useState(0);
    const sectionRef = useRef(null);

    const targetProjects = 60; // target number for Finished Projects
    const targetIssues = 4000; // target number for Issues Solved
    const targetCustomers = 1000; // target number for Happy Customers
    const targetInProgress = 1; // target number for Happy Customers

    const typingEffect = (target, setter) => {
        let count = 0;
        const step = Math.ceil(target / 100); // Dynamically adjust the step size
        const interval = setInterval(() => {
            count += step; // Increment by step size
            setter(count > target ? target : count); // Set final value if exceeded
            if (count >= target) {
                clearInterval(interval);
            }
        }, 30); // Adjust speed of typing effect here
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    typingEffect(targetProjects, setProjectCount);
                    typingEffect(targetIssues, setIssueCount);
                    typingEffect(targetCustomers, setCustomerCount);
                    typingEffect(targetInProgress, setInProgress);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const [formVisible, setFormVisible] = useState(true); // To toggle form visibility
    const [loading, setLoading] = useState(false); // To manage loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate required fields
        if (
            !formData.name ||
            !formData.email ||
            !formData.phone ||
            !formData.message
        ) {
            alert(
                "Please fill in all the required fields: Name, Email, Phone, and Message."
            );
            return;
        }

        setLoading(true); // Set loading to true when submitting

        // POST request to the backend
        try {
            const response = await fetch("https://mynode.geleceksoln.com/sendmail", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.success) {
                alert("Email sent successfully!");

                // Hide the form
                setFormVisible(false);

                // Re-show the form after 3 minutes
                setTimeout(() => {
                    setFormVisible(true);
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        message: "",
                    }); // Reset form data
                }, 180000); // 3 minutes = 180000 milliseconds
            } else {
                alert("Failed to send email.");
            }
        } catch (error) {
            console.error("Error sending email:", error);
        } finally {
            setLoading(false); // Reset loading state after submission
        }
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const items = [
        { src: "/img/enexa-logo.png" },
        { src: "/img/enexa_ANALYTICS.png" },
        { src: "/img/enexa-logo.png" },
        { src: "/img/enexa_ANALYTICS.png" },
    ];

    const handleNext = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % Math.ceil(items.length / 2)
        );
    };

    const handlePrev = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + Math.ceil(items.length / 2)) %
                Math.ceil(items.length / 2)
        );
    };

    // Automatically move to the next slide every 10 seconds
    useEffect(() => {
        const interval = setInterval(handleNext, 10000);
        return () => clearInterval(interval);
    }, []);

    // Group items in pairs for the carousel display
    const groupedItems = items.reduce((acc, item, index) => {
        if (index % 2 === 0) {
            acc.push([]); // Start a new row for every 2 items
        }
        acc[acc.length - 1].push(item);
        return acc;
    }, []);

    const products = [
        {
            id: 1,
            name: "Aura Mind",
            image: "/img/products/auramind.jpeg",
            shortDescription:
                "AuraMind is a Gen-AI based application where users can interact with their PDFs.",
        },
        {
            id: 2,
            name: "Safe City",
            image: "/img/products/SafeCity.png",
            shortDescription:
                "SafeCity is an AI-Based project for real-time traffic monitoring using live CCTV feeds.",
        },
        {
            id: 3,
            name: "GELCHECK Verification System",
            image: "/img/products/gelecheck.jpeg",
            shortDescription:
                "Ensures enhanced security, reduced fraud, and fast access with seamless integration.",
        },
    ];

    const formatNameForUrl = (name) => {
        return name.toLowerCase().replace(/\s+/g, "-");
    };

    return (
        <>
            <WhatsAppButton></WhatsAppButton>
            <div
                className="container-fluid hero-header position-relative"
                id="navbar"
            >
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <Link className="navbar-brand d-flex align-items-center" to="/">
                            <img
                                src="/img/GelecekLogo.png"
                                alt="Logo"
                                className="logo"
                                width="90px" loading="lazy"
                            />
                        </Link>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className="collapse navbar-collapse justify-content-between"
                            id="navbarNav"
                        >
                            <ul
                                className="navbar-nav mx-auto gap-4 mt-2 mb-2"
                                id="navbar-names"
                            >
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">
                                        Home
                                    </Link>
                                </li>

                                {/* Services Dropdown (Hover to open) */}
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Services
                                    </a>
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/oracle-ebs-implementation-and-extensions-services"
                                            >
                                                Oracle EBS Implementation and Extensions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/oracle-ebs-technical"
                                            >
                                                Oracle EBS Technical
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/cyber-and-it"
                                            >
                                                Cyber / IT Security
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/mobile-applications-development"
                                            >
                                                Mobile Applications Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/business-intelligence"
                                            >
                                                Business Intelligence
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/identity-access"
                                            >
                                                Identity and Access Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/web-and-software-development"
                                            >
                                                Web & Software Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/project-management"
                                            >
                                                Project Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/cloud-computing"
                                            >
                                                Cloud Computing
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/products">
                                        Products
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/careers">
                                        Careers
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact-us">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>

                            <div
                                className="d-flex align-items-center justify-content-center mb-3 mt-3 mt-lg-0 gap-3"
                                id="navbar-contat-div"
                            >
                                <div id="navbar-phone-div">
                                    <a href="tel:+923345592388" className="phone-number">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                        </svg>
                                        +92 334 5592388
                                    </a>
                                </div>
                                <Link to="/request-a-demo" className="text-dark" id="navbar-qoute">
                                    Request a Demo
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <video
                autoPlay
                poster="/img/video-poster-office.png"
                loop
                muted
                className="background-video background-video1 position-absolute top-0 start-0 w-100 h-100"
                style={{ objectFit: "cover", zIndex: -1 }}
            >
                <source src="/video/officevideo.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <div className="container-fluid m-0 p-0">
                <img src="donation.jpg" alt="" width="100%" id="background-img" />

                <div className="container">
                    <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner text-center text-white">
                            <div className="carousel-item active">
                                <p className="gelecek-is-your" data-aos="zoom-in-up">
                                    Where Technology
                                </p>
                                <p className="technology-partner" data-aos="zoom-in-up">
                                    Meets Ingenuity.
                                </p>
                                <p className="professional-team" data-aos="zoom-in-up">
                                    Your one stop service center to manage your businesses and
                                    needs.
                                </p>
                            </div>
                            <div className="carousel-item">
                                <p className="gelecek-is-your" data-aos="zoom-in-up">
                                    Excellence is
                                </p>
                                <p className="technology-partner" data-aos="zoom-in-up">
                                    Our Standard
                                </p>
                                <p className="professional-team" data-aos="zoom-in-up">
                                    Not The Goal
                                </p>
                            </div>
                        </div>

                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide="prev"
                        >
                            <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselExample"
                            data-bs-slide="next"
                        >
                            <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div id="buttons" className="mt-3">
                        <a
                            href="/about-us"
                            className="m-auto"
                            id="about-us-btn"
                            data-aos="fade-right"
                        >
                            About Us
                        </a>
                        <a href="/contact-us" id="contact-us-btn" data-aos="fade-left">
                            Contact Us
                        </a>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5">
                <div className="row">
                    {/* Left div */}
                    <div className="col-lg-6">
                        <img
                            src="/img/aboutus-img.png"
                            data-aos="zoom-in-right"
                            alt="Company"
                            width="98%" loading="lazy"
                        />
                    </div>

                    {/* Right div */}
                    <div className="col-lg-6 ps-4" data-aos="zoom-in-left">
                        <p
                            id="about-our-company"
                            className="mb-0 mt-3 text-dark text-decoration-underline fs-6"
                        >
                            About Our Company
                        </p>
                        <p id="Elevate-Your-tech" className="">
                            Your <span className="text-warning">IT</span> challenges, our{" "}
                            <span className="text-warning">innovative</span> solutions
                        </p>
                        <p id="We-have-been" className="lead">
                            We are a top-tier IT company offering specialized services in AI,
                            cybersecurity, web development, software engineering, and mobile
                            app creation. Our skilled professionals provide customized
                            solutions to help businesses thrive securely and innovate in the
                            digital landscape.
                        </p>
                        <a
                            href="/about-us"
                            className="d-inline-block ps-4 pe-4 pt-2 pb-2 mt-4 btn btn-warning fs-6"
                        >
                            Read More
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l14 0" />
                                <path d="M13 18l6 -6" />
                                <path d="M13 6l6 6" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            {/* Services */}
            <section className="bg-light py-5 py-xl-8">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div
                            className="col-12 col-md-10 col-lg-8 col-xl-7"
                            data-aos="fade-down"
                        >
                            <h3 className="fs-6 mb-2 text-secondary text-center text-uppercase">
                                What We Do?
                            </h3>
                            <h2 className="fs-1 mb-5 text-center font-weight-bold">
                                <b>Providing Perfect Solutions With Our Expert Services.</b>
                            </h2>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                        </div>
                    </div>
                </div>

                <div className="container services overflow-hidden">
                    <div className="row gy-4 gy-xl-0">
                        <div className="col-12 col-sm-6 col-xl-3">
                            <a
                                href="/services/web-and-software-development"
                                className="text-dark text-decoration-none"
                            >
                                <div className="card border-0 border-bottom border-warning shadow-sm d-flex flex-column h-100">
                                    <div
                                        className="card-body text-center p-4 p-xxl-3 flex-grow-1"
                                        data-aos="flip-left"
                                    >
                                        <img src="/icons/websoft.svg" alt="" loading="lazy" />
                                        <h4 className="mb-4">
                                            <strong>Web & Software Development</strong>
                                        </h4>
                                        <p className="mb-4 text-secondary">
                                            We can help you to design and develop mobile apps and
                                            websites that are user-friendly and visually appealing.
                                        </p>
                                        <a
                                            href="/services/web-and-software-development"
                                            className="fw-bold text-decoration-none link-warning"
                                        >
                                            Learn More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right-short"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-sm-6 col-xl-3">
                            <a
                                href="/services/mobile-applications-development"
                                className="text-dark text-decoration-none"
                            >
                                <div
                                    className="card border-0 border-bottom border-warning shadow-sm d-flex flex-column h-100"
                                    data-aos="flip-left"
                                >
                                    <div className="card-body text-center p-4 p-xxl-3 flex-grow-1">
                                        <img src="/icons/mobiledev.svg" alt="" loading="lazy" />
                                        <h4 className="mb-4">
                                            <strong>Mobile Applications Development</strong>
                                        </h4>
                                        <p className="mb-4 text-secondary">
                                            We can help you to design and develop mobile apps and
                                            websites that are user-friendly and visually appealing.
                                        </p>
                                        <a
                                            href="/services/mobile-applications-development"
                                            className="fw-bold text-decoration-none link-warning"
                                        >
                                            Learn More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right-short"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-sm-6 col-xl-3">
                            <a
                                href="/services/cyber-and-it"
                                className="text-dark text-decoration-none"
                            >
                                <div
                                    className="card border-0 border-bottom border-warning shadow-sm d-flex flex-column h-100"
                                    data-aos="flip-right"
                                >
                                    <div className="card-body text-center p-4 p-xxl-3 flex-grow-1">
                                        <img src="/icons/cyber.svg" alt="" loading="lazy" />
                                        <h4 className="mb-4">
                                            <strong>Cyber / IT Security</strong>
                                        </h4>
                                        <p className="mb-4 text-secondary">
                                            Defend Your Digital Frontier: Expert Cybersecurity
                                            Solutions.<br></br>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                        </p>
                                        <a
                                            href="/services/cyber-and-it"
                                            className="fw-bold text-decoration-none link-warning d-inline-flex align-items-center"
                                        >
                                            Learn More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right-short"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 col-sm-6 col-xl-3">
                            <a
                                href="/services/cloud-computing"
                                className="text-dark text-decoration-none"
                            >
                                <div
                                    className="card border-0 border-bottom border-warning shadow-sm d-flex flex-column h-100"
                                    data-aos="flip-right"
                                >
                                    <div className="card-body text-center p-4 p-xxl-3 flex-grow-1">
                                        <img src="/icons/cloud.svg" alt="" loading="lazy" />
                                        <h4 className="mb-4">
                                            <strong>Cloud Computing</strong>
                                        </h4>
                                        <p className="mb-4 text-secondary">
                                            First to mind when asked what the cloud is, a majority
                                            respond it's either an actual cloud, the sky, or something
                                            related to weather.<br></br>
                                            <br></br>
                                        </p>
                                        <a
                                            href="/services/cloud-computing"
                                            className="fw-bold text-decoration-none link-warning"
                                        >
                                            Learn More
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-arrow-right-short"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                                                />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        {/* <div className="col-12 text-center mt-3">
              <a href="/services"
                className="d-inline-block ps-4 pe-4 pt-2 pb-2 mt-4 btn btn-dark fs-6"
                data-aos="zoom-out-right"
              >
                View All Services
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l14 0" />
                  <path d="M13 18l6 -6" />
                  <path d="M13 6l6 6" />
                </svg>
              </a>
            </div> */}
                    </div>
                </div>
            </section>

            <section className="bsb-fact-5 py-3 py-md-5" ref={sectionRef}>
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div
                            className="col-12 col-md-10 col-lg-8 col-xl-7"
                            data-aos="fade-up"
                            data-aos-duration="3000"
                        >
                            <h3 className="fs-5 mb-2 text-secondary text-center text-uppercase">
                                Our Success
                            </h3>
                            <h2 className="display-5 mb-5 mb-xl-9 text-center">
                                <strong>We consistently deliver results.</strong>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 col-md-3 p-0">
                                        <div className="card border-0 bg-transparent">
                                            <div className="card-body text-center p-4 p-xxl-5">
                                                <h3 className="display-4 fw-bold mb-2">
                                                    {isVisible ? `${projectCount}+` : "0+"}
                                                </h3>
                                                <p className="fs-5 mb-0 text-secondary">
                                                    Finished Projects
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 p-0 border-start border-end">
                                        <div className="card border-0 bg-transparent">
                                            <div className="card-body text-center p-4 p-xxl-5">
                                                <h3 className="display-4 fw-bold mb-2">
                                                    {isVisible ? `${issueCount}+` : "0+"}
                                                </h3>
                                                <p className="fs-5 mb-0 text-secondary">
                                                    Issues Solved
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 p-0 border-end">
                                        <div className="card border-0 bg-transparent">
                                            <div className="card-body text-center p-4 p-xxl-5">
                                                <h3 className="display-4 fw-bold mb-2">
                                                    {isVisible ? `${customerCount}+` : "0+"}
                                                </h3>
                                                <p className="fs-5 mb-0 text-secondary">
                                                    Happy Customers
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 p-0">
                                        <div className="card border-0 bg-transparent">
                                            <div className="card-body text-center p-4 p-xxl-5">
                                                <h3 className="display-4 fw-bold mb-2">
                                                    {isVisible ? `${InProgressCount}M+` : "0+"}
                                                </h3>
                                                <p className="fs-5 mb-0 text-secondary">Transactions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="container">
        <div className="row g-5 align-items-center">
          <div
            className="col-sm-3 d-flex flex-column align"
            data-aos="fade-down-right"
          >
            <h2>
              <strong>Enexa e-Office</strong>
            </h2>
            <h5 className="text-decoration-underline mb-4">
              Over 10+ Global Clients
            </h5>
            <p className="lead">
              AOS is an office automation software that streamlines document
              management, chat, and notification verification. It enables
              efficient handling of files, secure messaging for team
              collaboration, and real-time notifications, ensuring tasks are
              managed and verified promptly.
            </p>
            <div>
              <a href="/products" className="btn btn-outline-dark">
                View More Products
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M5 12l14 0" />
                  <path d="M15 16l4 -4" />
                  <path d="M15 8l4 4" />
                </svg>
              </a>
            </div>
          </div>
          <div className="col-sm-7">
            <img
              data-aos="fade-left"
              src="/img/services/Enexa.png"
              style={{ width: "150%", borderRadius: "10px" }}
            />
          </div>
        </div>
      </div> */}

            <section className="py-5 py-xl-8 bg-warning-product">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div
                            className="col-12 col-md-10 col-lg-8 col-xl-7"
                            data-aos="fade-down"
                        >
                            <h3 className="fs-6 mb-2 text-secondary text-center text-uppercase">
                                Projects
                            </h3>
                            <h2 className="fs-1 mb-5 text-center font-weight-bold">
                                <b>Our Creative Solution.</b>
                            </h2>
                            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
                        </div>
                    </div>
                    <div className="product-card mb-4" data-aos="zoom-in">
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-4">
                                <img
                                    className="w-100 rounded-3 product-img"
                                    src="/img/services/ENEXAe-Office.png" loading="lazy"
                                    alt="enexa"
                                />
                            </div>
                            <div className="col-sm-8">
                                <h3>
                                    <strong>Enexa e-Office</strong>
                                </h3>
                                <p className="lead">
                                    An office automation system (OAS) uses software and technology
                                    to automate office tasks like document management,
                                    communication, scheduling, and data processing, improving
                                    efficiency and productivity.
                                </p>
                                <a
                                    href="/product/enexa-e-office"
                                    className="btn btn-outline-dark text-decoration-none btn-sm"
                                >
                                    Read More{" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-circle-arrow-right"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M12 3a9 9 0 1 0 0 18a9 9 0 0 0 0 -18" />
                                        <path d="M16 12l-4 -4" />
                                        <path d="M16 12h-8" />
                                        <path d="M12 16l4 -4" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {products.map((product) => (
                            <div
                                className="col-sm-4 mb-4"
                                data-aos="zoom-in"
                                key={product.id}
                            >
                                <div className="rounded-3 p-3 product-card">
                                    <img
                                        src={product.image}
                                        className="w-100 rounded-3"
                                        alt={product.name} loading="lazy"
                                    />
                                    <h4 className="mt-2">{product.name}</h4>
                                    <p>{product.shortDescription}</p>
                                    <a
                                        href={`/product/${formatNameForUrl(product.name)}`}
                                        className="btn btn-outline-dark w-100"
                                    >
                                        Read More
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* <div className="container text-center my-3">
        <div className="row mx-auto my-auto">
          <div id="recipeCarousel" className="carousel slide w-100">
            <div className="carousel-inner w-100 p-4" role="listbox">
              {groupedItems.map((rowItems, rowIndex) => (
                <div
                  key={rowIndex}
                  className={`carousel-item ${
                    rowIndex === currentIndex ? "active" : ""
                  }`}
                >
                  <div className="d-flex justify-content-around">
                    {rowItems.map((item, itemIndex) => (
                      <div className="col-md-3" key={itemIndex}>
                        <div>
                          <img
                            className="img-fluid"
                            src={item.src}
                            alt={`Slide ${rowIndex * 2 + itemIndex + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <a
              className="carousel-control-prev w-auto"
              onClick={handlePrev}
              role="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M15 6l-6 6l6 6" />
              </svg>
              <span className="sr-only"></span>
            </a>
            <a
              className="carousel-control-next w-auto"
              onClick={handleNext}
              role="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 6l6 6l-6 6" />
              </svg>
              <span className="sr-only"></span>
            </a>
          </div>
        </div>
      </div> */}

            <div className="container-fluid px-2 px-md-4 py-5 mx-auto">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-11 pt-5">
                        <div
                            className="card card-main border-0 text-center"
                            data-aos="fade-down"
                        >
                            <div
                                id="carouselExampleIndicators"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="0"
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="1"
                                        className="active"
                                    ></li>
                                    <li
                                        data-target="#carouselExampleIndicators"
                                        data-slide-to="2"
                                    ></li>
                                </ol>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="card border-0 card-0 p-4 bg-dark">
                                            <div className="card profile py-3 px-4">
                                                <div className="text-center">
                                                    <img
                                                        src="/img/hishamsarwar.jpeg"
                                                        className="img-fluid profile-pic"
                                                        alt="Marielle Haag" loading="lazy"
                                                    />
                                                </div>
                                                <h6 className="mb-0 mt-2">
                                                    <strong>Hisham Sarwar</strong>
                                                </h6>
                                                <small>CEO of Innovista</small>
                                            </div>
                                            <img
                                                className="img-fluid open-quotes"
                                                src="https://i.imgur.com/Hp91vdT.png"
                                                width="20"
                                                height="20"
                                                alt="open quotes" loading="lazy"
                                            />
                                            <p className="content mb-0">
                                                Gelecek Solution is recognized as a pioneering force in
                                                the tech industry, dedicated to delivering innovative
                                                software solutions that enable businesses to achieve
                                                their goals efficiently and effectively. Their
                                                unwavering commitment to quality and customer
                                                satisfaction distinguishes them as a trusted partner for
                                                organizations seeking to enhance their digital
                                                capabilities.
                                            </p>
                                            <img
                                                className="img-fluid close-quotes ml-auto"
                                                src="https://i.imgur.com/iPcHyJK.png"
                                                width="20"
                                                height="20"
                                                alt="close quotes" loading="lazy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact US */}
            <div id="contact" className="contact-area section-padding bg-dark pb-5">
                <div className="container">
                    <div
                        className="section-title text-center text-light"
                        data-aos="flip-up"
                    >
                        <hr></hr>
                        <br></br>
                        <br></br>
                        <h1>
                            <strong>Get in Touch</strong>
                        </h1>
                        <p>
                            This is your gateway to connect with us directly. Whether you have
                            inquiries, feedback, or simply want to say hello.
                        </p>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-lg-7 mr-3">
                            {" "}
                            {/* Added the class here */}
                            <div className="contact" data-aos="fade-right">
                                {formVisible ? (
                                    <form className="form" onSubmit={handleSubmit}>
                                        <div className="row g-2">
                                            <div className="form-group col-md-6">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-6 mt-2">
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-12 mt-2">
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    className="form-control"
                                                    placeholder="Your Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12 text-center mt-2">
                                                <button
                                                    type="submit"
                                                    className="btn btn-contact-bg"
                                                    title="Submit Your Message!"
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    ) : (
                                                        "Send Message"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                ) : (
                                    <p className="text-white">
                                        Form submitted successfully. Please wait while we process
                                        your request.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-5 text-light">
                            <div className="contactrightsection" data-aos="fade-left">
                                <div className="single_address">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                    </svg>{" "}
                                    <h4>Our Address</h4>
                                    <p>
                                        Office No 1017, Alpha Techno Square, National Aerospace & Technology Park (NASTP), Nur Khan Airbase, Old Airport Road, Rawalpindi
                                    </p>
                                </div>
                                <div className="single_address">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-mail"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>{" "}
                                    <h4>Send your message</h4>
                                    <p>info@geleceksoln.com</p>
                                </div>
                                <div className="single_address">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>{" "}
                                    <h4>Call us on</h4>
                                    <p>+92 334 5592388</p>
                                </div>
                                <div className="single_address">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-clock"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M12 7v5l3 3" />
                                    </svg>
                                    <h4>Work Time</h4>
                                    <p>
                                        Mon - Fri: 08.00 - 16.00. <br />
                                        Sat: 10.00 - 14.00
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="tabs" className="project-tab mt-2 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2
                                className="fs-1 mb-5 text-center font-weight-bold"
                                data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500"
                            >
                                <b>Technologies Powering Our Solutions</b>
                            </h2>
                            <nav>
                                <div
                                    className="nav nav-tabs nav-fill"
                                    id="nav-tab"
                                    role="tablist"
                                >
                                    <a
                                        className="nav-item nav-link active"
                                        id="nav-frontend-tab"
                                        data-bs-toggle="tab"
                                        href="#nav-frontend"
                                        role="tab"
                                        aria-controls="nav-frontend"
                                        aria-selected="true"
                                    >
                                        Front End
                                    </a>
                                    <a
                                        className="nav-item nav-link"
                                        id="nav-backend-tab"
                                        data-bs-toggle="tab"
                                        href="#nav-backend"
                                        role="tab"
                                        aria-controls="nav-backend"
                                        aria-selected="false"
                                    >
                                        Backend
                                    </a>
                                    <a
                                        className="nav-item nav-link"
                                        id="nav-cms-tab"
                                        data-bs-toggle="tab"
                                        href="#nav-cms"
                                        role="tab"
                                        aria-controls="nav-cms"
                                        aria-selected="false"
                                    >
                                        CMS
                                    </a>
                                    <a
                                        className="nav-item nav-link"
                                        id="nav-servers-tab"
                                        data-bs-toggle="tab"
                                        href="#nav-servers"
                                        role="tab"
                                        aria-controls="nav-servers"
                                        aria-selected="false"
                                    >
                                        Servers
                                    </a>
                                    <a
                                        className="nav-item nav-link"
                                        id="nav-software-tab"
                                        data-bs-toggle="tab"
                                        href="#nav-software"
                                        role="tab"
                                        aria-controls="nav-software"
                                        aria-selected="false"
                                    >
                                        Software
                                    </a>
                                </div>
                            </nav>
                            <div className="tab-content mt-2" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="nav-frontend"
                                    role="tabpanel"
                                    aria-labelledby="nav-frontend-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-html5"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M20 4l-2 14.5l-6 2l-6 -2l-2 -14.5z" />
                                                <path d="M15.5 8h-7l.5 4h6l-.5 3.5l-2.5 .75l-2.5 -.75l-.1 -.5" />
                                            </svg>
                                            <h5>HTML</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-react"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M6.306 8.711c-2.602 .723 -4.306 1.926 -4.306 3.289c0 2.21 4.477 4 10 4c.773 0 1.526 -.035 2.248 -.102" />
                                                <path d="M17.692 15.289c2.603 -.722 4.308 -1.926 4.308 -3.289c0 -2.21 -4.477 -4 -10 -4c-.773 0 -1.526 .035 -2.25 .102" />
                                                <path d="M6.305 15.287c-.676 2.615 -.485 4.693 .695 5.373c1.913 1.105 5.703 -1.877 8.464 -6.66c.387 -.67 .733 -1.339 1.036 -2" />
                                                <path d="M17.694 8.716c.677 -2.616 .487 -4.696 -.694 -5.376c-1.913 -1.105 -5.703 1.877 -8.464 6.66c-.387 .67 -.733 1.34 -1.037 2" />
                                                <path d="M12 5.424c-1.925 -1.892 -3.82 -2.766 -5 -2.084c-1.913 1.104 -1.226 5.877 1.536 10.66c.386 .67 .793 1.304 1.212 1.896" />
                                                <path d="M12 18.574c1.926 1.893 3.821 2.768 5 2.086c1.913 -1.104 1.226 -5.877 -1.536 -10.66c-.375 -.65 -.78 -1.283 -1.212 -1.897" />
                                                <path d="M11.5 12.866a1 1 0 1 0 1 -1.732a1 1 0 0 0 -1 1.732z" />
                                            </svg>
                                            <h5>REACT</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-angular"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5.428 17.245l6.076 3.471a1 1 0 0 0 .992 0l6.076 -3.471a1 1 0 0 0 .495 -.734l1.323 -9.704a1 1 0 0 0 -.658 -1.078l-7.4 -2.612a1 1 0 0 0 -.665 0l-7.399 2.613a1 1 0 0 0 -.658 1.078l1.323 9.704a1 1 0 0 0 .495 .734z" />
                                                <path d="M9 15l3 -8l3 8" />
                                                <path d="M10 13h4" />
                                            </svg>
                                            <h5>ANGULAR</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-file-type-ts"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-1" />
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                <path d="M9 20.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                                                <path d="M3.5 15h3" />
                                                <path d="M5 15v6" />
                                            </svg>
                                            <h5>TYPESCRIPT</h5>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="nav-backend"
                                    role="tabpanel"
                                    aria-labelledby="nav-backend-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-php"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 12m-10 0a10 9 0 1 0 20 0a10 9 0 1 0 -20 0" />
                                                <path d="M5.5 15l.395 -1.974l.605 -3.026h1.32a1 1 0 0 1 .986 1.164l-.167 1a1 1 0 0 1 -.986 .836h-1.653" />
                                                <path d="M15.5 15l.395 -1.974l.605 -3.026h1.32a1 1 0 0 1 .986 1.164l-.167 1a1 1 0 0 1 -.986 .836h-1.653" />
                                                <path d="M12 7.5l-1 5.5" />
                                                <path d="M11.6 10h2.4l-.5 3" />
                                            </svg>
                                            <h5>PHP</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-nodejs"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M9 9v8.044a2 2 0 0 1 -2.996 1.734l-1.568 -.9a3 3 0 0 1 -1.436 -2.561v-6.635a3 3 0 0 1 1.436 -2.56l6 -3.667a3 3 0 0 1 3.128 0l6 3.667a3 3 0 0 1 1.436 2.561v6.634a3 3 0 0 1 -1.436 2.56l-6 3.667a3 3 0 0 1 -3.128 0" />
                                                <path d="M17 9h-3.5a1.5 1.5 0 0 0 0 3h2a1.5 1.5 0 0 1 0 3h-3.5" />
                                            </svg>
                                            <h5>NODE JS</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-laravel"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 17l8 5l7 -4v-8l-4 -2.5l4 -2.5l4 2.5v4l-11 6.5l-4 -2.5v-7.5l-4 -2.5z" />
                                                <path d="M11 18v4" />
                                                <path d="M7 15.5l7 -4" />
                                                <path d="M14 7.5v4" />
                                                <path d="M14 11.5l4 2.5" />
                                                <path d="M11 13v-7.5l-4 -2.5l-4 2.5" />
                                                <path d="M7 8l4 -2.5" />
                                                <path d="M18 10l4 -2.5" />
                                            </svg>
                                            <h5>LARAVEL</h5>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="nav-cms"
                                    role="tabpanel"
                                    aria-labelledby="nav-cms-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-wordpress"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M9.5 9h3" />
                                                <path d="M4 9h2.5" />
                                                <path d="M11 9l3 11l4 -9" />
                                                <path d="M5.5 9l3.5 11l3 -7" />
                                                <path d="M18 11c.177 -.528 1 -1.364 1 -2.5c0 -1.78 -.776 -2.5 -1.875 -2.5c-.898 0 -1.125 .812 -1.125 1.429c0 1.83 2 2.058 2 3.571z" />
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                            </svg>
                                            <h5>WORDPRESS</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-shopee"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l.867 12.143a2 2 0 0 0 2 1.857h10.276a2 2 0 0 0 2 -1.857l.867 -12.143h-16z" />
                                                <path d="M8.5 7c0 -1.653 1.5 -4 3.5 -4s3.5 2.347 3.5 4" />
                                                <path d="M9.5 17c.413 .462 1 1 2.5 1s2.5 -.897 2.5 -2s-1 -1.5 -2.5 -2s-2 -1.47 -2 -2c0 -1.104 1 -2 2 -2s1.5 0 2.5 1" />
                                            </svg>
                                            <h5>SHOPIFY</h5>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="nav-servers"
                                    role="tabpanel"
                                    aria-labelledby="nav-servers-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-aws"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M17 18.5a15.198 15.198 0 0 1 -7.37 1.44a14.62 14.62 0 0 1 -6.63 -2.94" />
                                                <path d="M19.5 21c.907 -1.411 1.451 -3.323 1.5 -5c-1.197 -.773 -2.577 -.935 -4 -1" />
                                                <path d="M3 11v-4.5a1.5 1.5 0 0 1 3 0v4.5" />
                                                <path d="M3 9h3" />
                                                <path d="M9 5l1.2 6l1.8 -4l1.8 4l1.2 -6" />
                                                <path d="M18 10.25c0 .414 .336 .75 .75 .75h1.25a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-1a1 1 0 0 1 -1 -1v-1a1 1 0 0 1 1 -1h1.25a.75 .75 0 0 1 .75 .75" />
                                            </svg>
                                            <h5>AWS</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-server-bolt"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" />
                                                <path d="M15 20h-9a3 3 0 0 1 -3 -3v-2a3 3 0 0 1 3 -3h12" />
                                                <path d="M7 8v.01" />
                                                <path d="M7 16v.01" />
                                                <path d="M20 15l-2 3h3l-2 3" />
                                            </svg>
                                            <h5>INTERSERVER</h5>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="nav-software"
                                    role="tabpanel"
                                    aria-labelledby="nav-software-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                class="icon icon-tabler icons-tabler-filled icon-tabler-copyright"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2.34 5.659a4.016 4.016 0 0 0 -5.543 .23a3.993 3.993 0 0 0 0 5.542a4.016 4.016 0 0 0 5.543 .23a1 1 0 0 0 -1.32 -1.502c-.81 .711 -2.035 .66 -2.783 -.116a1.993 1.993 0 0 1 0 -2.766a2.016 2.016 0 0 1 2.783 -.116a1 1 0 0 0 1.32 -1.501z" />
                                            </svg>
                                            <h5>CANVA</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-adobe-photoshop"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 12c0 -4.243 0 -6.364 1.318 -7.682s3.44 -1.318 7.682 -1.318s6.364 0 7.682 1.318s1.318 3.44 1.318 7.682s0 6.364 -1.318 7.682s-3.44 1.318 -7.682 1.318s-6.364 0 -7.682 -1.318s-1.318 -3.44 -1.318 -7.682" />
                                                <path d="M6.79 15.79v-3.79m0 0v-2.724c0 -.11 0 -.165 .004 -.211c.044 -.45 .4 -.806 .85 -.85c.046 -.004 .101 -.004 .211 -.004h1.303a1.895 1.895 0 1 1 0 3.789z" />
                                                <path d="M17.178 11.263c-.164 -.659 -.935 -1.158 -1.862 -1.158c-1.047 0 -1.895 .637 -1.895 1.421c0 .785 .848 1.421 1.895 1.421c1.046 0 1.895 .637 1.895 1.421c0 .785 -.849 1.421 -1.895 1.421c-.93 0 -1.704 -.502 -1.864 -1.165" />
                                            </svg>
                                            <h5>PHOTOSHOP</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-adobe-illustrator"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 12c0 -4.243 0 -6.364 1.318 -7.682s3.44 -1.318 7.682 -1.318s6.364 0 7.682 1.318s1.318 3.44 1.318 7.682s0 6.364 -1.318 7.682s-3.44 1.318 -7.682 1.318s-6.364 0 -7.682 -1.318s-1.318 -3.44 -1.318 -7.682" />
                                                <path d="M12.947 15.79l-.82 -2.653m-4.864 2.652l.82 -2.652m0 0l.687 -2.218c.558 -1.806 .838 -2.708 1.335 -2.708c.498 0 .777 .902 1.336 2.708l.686 2.218m-4.043 0h4.043" />
                                                <path d="M15.789 15.789v-4.736" />
                                                <path d="M15.789 8.684v-.473" />
                                            </svg>
                                            <h5>ILLUSTRATOR</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-adobe-after-effect"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 12c0 -4.243 0 -6.364 1.318 -7.682s3.44 -1.318 7.682 -1.318s6.364 0 7.682 1.318s1.318 3.44 1.318 7.682s0 6.364 -1.318 7.682s-3.44 1.318 -7.682 1.318s-6.364 0 -7.682 -1.318s-1.318 -3.44 -1.318 -7.682" />
                                                <path d="M12 15.79l-.82 -2.653m-4.864 2.652l.82 -2.652m0 0l.686 -2.218c.559 -1.806 .838 -2.708 1.336 -2.708s.777 .902 1.335 2.708l.686 2.218m-4.043 0h4.043" />
                                                <path d="M13.895 12.824v1.07a1.895 1.895 0 0 0 3.54 .942m-3.54 -2.012v-.824a1.895 1.895 0 1 1 3.79 0v.824z" />
                                            </svg>{" "}
                                            <h5>AFTEREFFECTS</h5>
                                        </div>
                                        <div className="col-md-2 text-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="64"
                                                height="64"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="icon icon-tabler icons-tabler-outline icon-tabler-brand-visual-studio"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 8l2 -1l10 13l4 -2v-12l-4 -2l-10 13l-2 -1z" />
                                            </svg>
                                            <h5>VS CODE</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="container-fluid p-0 m-0 mt-5">
        <div className="row">
          <div className="col-sm-12">
            <div id="google-map" class="google-map">
              <div class="map-container">
                <div id="googleMaps" class="google-map-container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13291.564974137022!2d73.1002665!3d33.6081262!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeb8c67a97d8b%3A0x47a39a9b3da1914!2sGelecek%20Solutions!5e0!3m2!1sen!2s!4v1710845402679!5m2!1sen!2s"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Map"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
            <footer
                className="footer-section"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
            >
                <div className="container">
                    <div className="footer-cta pt-5 pb-5">
                        <div className="row g-4">
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta  d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>
                                            Office No 1017, Alpha Techno Square, National Aerospace & Technology Park (NASTP), Nur Khan Airbase, Old Airport Road, Rawalpindi
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>+92 334 5592388</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta  d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-mail"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Mail us</h4>
                                        <span>info@geleceksoln.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-content pt-5 pb-5">
                        <div className="row g-4">
                            <div className="col-xl-4 col-lg-4 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <a href="index.html">
                                            <img
                                                src="/img/GelecekLogo.png"
                                                className="img-fluid"
                                                width={150}
                                                alt="logo" loading="lazy"
                                            />
                                        </a>
                                    </div>
                                    <div className="footer-text">
                                        <p>
                                            Welcome to the digital realm where innovation meets
                                            expertise, and every line of code is a brushstroke on the
                                            canvas of tomorrow.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="/products">Products</a>
                                        </li>
                                        <li>
                                            <a href="/careers">Careers</a>
                                        </li>
                                        <li>
                                            <a href="/about-us">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Services</h3>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="/services/web-and-software-development">
                                                Web & Software Development
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/mobile-applications-development">
                                                Mobile Applications Development
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/cyber-and-it">Cyber / IT Security</a>
                                        </li>
                                        <li>
                                            <a href="/services/project-management">
                                                Project Management
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/cloud-computing">Cloud Computing</a>
                                        </li>
                                        {/* <li>
                      <a href="/services/business-intelligence">Business Intelligence</a>
                    </li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Subscribe</h3>
                                    </div>
                                    <div className="footer-text mb-25">
                                        <p>
                                            Don’t miss to subscribe to our new feeds, kindly fill the
                                            form below.
                                        </p>
                                    </div>
                                    <div className="subscribe-form">
                                        <form action="#">
                                            <input type="text" placeholder="Email Address" />
                                            <button>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-send-2"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z" />
                                                    <path d="M6.5 12h14.5" />
                                                </svg>{" "}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <div className="copyright-text">
                                    <p className="text-center">
                                        Copyright &copy; 2024, All Rights Reserved{" "}
                                        <a href="https://geleceksoln.com/">Geleceks Solutions</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Home;
