import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const OracleForm = () => {
  return (
    <>
      <MainHeader>
        <div className="container-fluid p-0 m-0">
          <div className="container pt-5 pb-5">
            <div className="row">
              {/* Left Section */}
              <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
                <p id="service_heading">
                  Oracle EBS Implementation and Extensions Services
                </p>
                <p id="services_para">
                  Oracle EBS Implementation and Extension Services offer the
                  backbone for your business growth, ensuring seamless
                  operations and long-term stability with tailored enterprise
                  solutions.
                </p>
                <a href="#" id="custom_quote_btn" className="">
                  Custom Quote
                </a>
                <p className="mt-4" id="or_call_us_btn">
                  or Call Us:{" "}
                  <a href="tel:+923345592388" className="">
                  +92 334 5592388
                  </a>
                </p>
              </div>

              {/* Right Section */}
              <div className="col-md-5"  data-aos="fade-left">
                <Servicecontactform></Servicecontactform>
              </div>
            </div>
          </div>
        </div>
        <video
          autoPlay
          preload="none"
          poster="/img/other-pages-poster.png"
          loop
          muted
          className="background-video position-absolute top-0 start-0 w-100 h-100"
          style={{
            objectFit: "cover",
            zIndex: -1,
            filter: "brightness(80%)",
          }}
        >
          <source src="/video/Main-heading.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="container mt-5">
          <br></br>
          <br></br>
          <div className="row">
            <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
              <img src="/img/services/oracle-img.jpg" alt="" />
            </div>
            <div className="col-md-7" data-aos="fade-left">
              <p id="oracle_description_heading">
                Seamless Oracle EBS Solutions: Implementation and Custom
                Extensions
              </p>
              <p id="oracle_description_para1">
                Our experienced team specializes in Oracle databases,
                applications, and development. With a talented group of
                developers, we focus on extending the capabilities of Oracle
                E-Business Suite using Oracle Applications Express (APEX), a
                low-code tool. By leveraging APEX, we enhance functionality and
                improve the user experience, tailoring solutions to meet your
                unique requirements.
              </p>
              <p id="oracle_description_para2">
                Our services cover a wide range of Oracle E-Business Suite
                extensions, including application development, developer
                resourcing, and training. We empower your teams to become
                self-sufficient in application development and management.
              </p>
              <p id="oracle_description_para3">
                Known as the leading Oracle APEX team in the Gelecek, we have
                built a strong reputation for our in-depth understanding of
                Oracle, E-Business Suite, and APEX. Clients choose our Oracle
                E-Business Suite Extension services because they trust our
                expertise and know we can thoughtfully and effectively extend
                their mission-critical applications to meet specific business
                needs, often within tight timelines.
              </p>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </MainHeader>
    </>
  );
};

export default OracleForm;
