import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const OracleFormBanner = () => {
  return (
    <div className="container-fluid p-0 m-0" id="Oracle-form-banner">
      <div className="container pt-5 pb-5">
        <div className="row">
          {/* Left Section */}
          <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
            <p id="service_heading">Project Management</p>
            <p id="services_para">
              Project Management services provide structured planning,
              execution, and oversight to ensure your projects are delivered on
              time and within budget. We offer end-to-end management, from
              initiation and resource allocation to risk mitigation and project
              completion, ensuring alignment with your business objectives.
            </p>
            <a href="#" id="custom_quote_btn" className="">
              Custom Quote
            </a>
            <p className="mt-4" id="or_call_us_btn">
              or Call Us:{" "}
              <a href="tel:+923345592388" className="">
              +92 334 5592388
              </a>
            </p>
          </div>
          {/* Right Section */}
          <div className="col-md-5" data-aos="fade-left">
            <Servicecontactform></Servicecontactform>
          </div>
        </div>
      </div>
      <video
        autoPlay
        preload="none"
        poster="/img/other-pages-poster.png"
        loop
        muted
        className="background-video position-absolute top-0 start-0 w-100 h-100"
        style={{
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(80%)",
        }}
      >
        <source src="/video/Main-heading.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const OracleDescription = () => {
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
          <img src="/img/services/Project_Management_img.jpg" alt="" />
        </div>
        <div className="col-md-7" data-aos="fade-left">
          <p id="oracle_description_heading">
            From Concept to Completion: Guiding Projects to Success
          </p>
          <p id="oracle_description_para">
            Project management is the practice of planning, organizing, and
            executing projects to achieve specific goals and deliver desired
            outcomes within defined constraints. It involves overseeing the
            project’s scope, time, cost, quality, resources, and stakeholders to
            ensure successful project completion.
          </p>
          {/* Add additional description paragraphs similarly */}
        </div>
      </div>
    </div>
  );
};

const OracleFAQ = () => {
  return (
    <div className="container" data-aos="zoom-in">
      <p id="faq_heading" className="mb-0">Frequently Asked Questions</p>
      <div
        className="accordion accordion-flush mt-5"
        id="accordionFlushExample"
      >
        <div className="accordion-item pt-3 pb-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              What is included in your Project Management services?
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Our Project Management services include project planning, resource
              allocation, timeline management, risk assessment, quality control,
              and ongoing monitoring to ensure successful project completion on
              time and within budget.
            </div>
          </div>
        </div>
        <div className="accordion-item pt-3 pb-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              How do you ensure projects stay on track and meet deadlines?
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              We use proven project management methodologies, such as Agile or
              Waterfall, to monitor progress, track milestones, and make
              adjustments as needed to keep the project on schedule while
              maintaining clear communication with stakeholders.
            </div>
          </div>
        </div>
        <div className="accordion-item pt-3 pb-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              Can you manage both small and large-scale projects?
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Yes, we specialize in managing projects of all sizes, from small
              internal initiatives to large, complex, multi-phase projects,
              ensuring that each project receives the appropriate level of
              attention and resources.
            </div>
          </div>
        </div>
        <div className="accordion-item pt-3 pb-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFour"
              aria-expanded="false"
              aria-controls="flush-collapseFour"
            >
              How do you handle risks and unexpected challenges during a
              project?
            </button>
          </h2>
          <div
            id="flush-collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              We proactively identify potential risks during the planning phase
              and develop contingency plans. If challenges arise, our team
              swiftly addresses them, mitigating risks to minimize impact on the
              project timeline and budget.
            </div>
          </div>
        </div>
        <div className="accordion-item pt-3 pb-3">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseFive"
              aria-expanded="false"
              aria-controls="flush-collapseFive"
            >
              Do you provide post-project support and evaluation?
            </button>
          </h2>
          <div
            id="flush-collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              Yes, after project completion, we offer post-project reviews,
              performance evaluations, and ongoing support to ensure that the
              project has met its objectives and any necessary adjustments are
              made for future improvements.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OracleFormPage = () => {
  return (
    <>
      <MainHeader>
        <OracleFormBanner />
        <OracleDescription />
        <br></br>
        <br></br>
        <OracleFAQ />
      </MainHeader>
    </>
  );
};

export default OracleFormPage;
