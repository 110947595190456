import React, { useState } from "react";
import MainHeader from "../../components/Main-header"; 
import Servicecontactform from "./servicecontactform"; 
import "../../css/services.css";

const Webandsoft = () => {
  // State to hold form data
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    subject: "",
    message: "",
  });

  // Event handler for form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Event handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted: ", formData);
    // Perform form submission logic (API call or further processing)
  };

  return (
    <>
      <MainHeader>
        {/* Oracle Form Page Banner Start */}
        <div className="container-fluid p-0 m-0" id="Oracle-form-banner">
          <div className="container pt-5 pb-5">
            <div className="row">
              {/* Left Section */}
              <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
                <p id="service_heading">Web & Software Development</p>
                <p id="services_para">
                  Web & Software Development services deliver custom, scalable
                  solutions tailored to your business needs. We offer end-to-end
                  development, from design and coding to deployment and ongoing
                  support, ensuring your digital products are secure, efficient,
                  and aligned with your organization's goals.
                </p>
                <a href="#" id="custom_quote_btn" className="">
                  Custom Quote
                </a>
                <p className="mt-4" id="or_call_us_btn">
                  or Call Us: <a href="tel:+923345592388">+92 334 5592388</a>
                </p>
              </div>

              {/* Right Section */}
              <div className="col-md-5" data-aos="fade-left">
                <Servicecontactform></Servicecontactform>
              </div>
            </div>
          </div>
        </div>
        <video
            autoPlay
            preload="none"
            poster="/img/other-pages-poster.png"
            loop
            muted
            className="background-video position-absolute top-0 start-0 w-100 h-100"
            style={{
              objectFit: "cover",
              zIndex: -1,
              filter: "brightness(80%)",
            }}
          >
            <source src="/video/Main-heading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        {/* Oracle Form Page Banner End */}

        {/* Oracle Description Section Start */}
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
              <img src="/img/services/Web_Dev_img.jpg" alt="Web Development" />
            </div>
            <div className="col-md-7" data-aos="fade-left">
              <p id="oracle_description_heading">
                Building Tomorrows Solutions Today: Cutting-Edge Development and
                Implementation
              </p>
              <p id="oracle_description_para">
                Web and software development refer to the processes of creating,
                designing, and building websites and software applications.
                These development activities involve a combination of
                programming, design, testing, and deployment to create
                functional, user-friendly, and efficient digital solutions.
              </p>
              {/* More description paragraphs */}
            </div>
          </div>
        </div>
        {/* Oracle Description Section End */}

        {/* Oracle FAQs Section Start */}
        <div className="container mt-1 mb-5" data-aos="zoom-in">
          <br></br>
          <br></br>
          <p id="faq_heading">
            Frequently Asked Questions
          </p>
          <div
            className="accordion accordion-flush mt-5"
            id="accordionFlushExample"
          >
            {faqData.map((faq, index) => (
              <div className="accordion-item pt-3 pb-3" key={index}>
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed fw-bold"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index + 1}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse${index + 1}`}
                  >
                    {faq.question}
                  </button>
                </h2>
                <div
                  id={`flush-collapse${index + 1}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Oracle FAQs Section End */}
      </MainHeader>
    </>
  );
};

// FAQs data
const faqData = [
  {
    question: "What services are included in Web & Software Development?",
    answer:
      "Our Web & Software Development services include custom website design, software development, e-commerce solutions, mobile app development, and ongoing maintenance to ensure your digital products are robust and efficient.",
  },
  {
    question: "Do you develop both front-end and back-end systems?",
    answer:
      "Yes, we provide full-stack development services, covering both front-end user interfaces and back-end server functionality, ensuring a seamless and cohesive digital experience.",
  },
  {
    question:
      "Can you integrate third-party services into our web or software solutions?",
    answer:
      "Absolutely! We can integrate third-party APIs, payment gateways, CRM systems, and other services to enhance the functionality and performance of your website or software.",
  },
  {
    question:
      "How long does it take to develop a custom web or software solution?",
    answer:
      "Development timelines vary based on project complexity, but we work closely with you to set realistic deadlines, typically ranging from a few weeks to several months depending on the scope.",
  },
  {
    question: "What kind of ongoing support do you offer after development?",
    answer:
      "We offer comprehensive post-development support, including regular updates, bug fixes, performance optimization, and new feature integration to ensure your solution remains up-to-date and effective.",
  },
];

export default Webandsoft;
