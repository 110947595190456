import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const CloudComputingPage = () => {
  return (
    <MainHeader>
    <div className="container-fluid p-0 m-0">
      <div className="container pt-5 pb-5">
        <div className="row">
          {/* Left Section */}
          <div className="col-md-7 d-flex flex-column" id="oracle_left_div" data-aos="fade-right">
            <p id="service_heading">Cloud Computing</p>
            <p id="services_para">
              Cloud Computing services offer scalable and flexible solutions to meet your organization’s technology needs. We provide end-to-end cloud management, from deployment and data migration to security and ongoing support, ensuring seamless integration and optimizing your business operations in the cloud.
            </p>
            <a href="#" id="custom_quote_btn" className="">
              Custom Quote
            </a>
            <p className="mt-4" id="or_call_us_btn">
              or Call Us: <a href="tel:+923345592388" className="">+92 334 5592388</a>
            </p>
          </div>

          {/* Right Section */}
          <div className="col-md-5" data-aos="fade-left">
           <Servicecontactform></Servicecontactform>
          </div>
        </div>
      </div>
      <video
        autoPlay
        preload="none"
        poster="/img/other-pages-poster.png"
        loop
        muted
        className="background-video position-absolute top-0 start-0 w-100 h-100"
        style={{
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(80%)",
        }}
      >
        <source src="/video/Main-heading.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Oracle Description Section */}
      <div className="container mt-5">
        <br></br>
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
            <img src="/img/services/Cloud_Computing_img.jpg" alt="Cloud Computing" loading="lazy" />
          </div>
          <div className="col-md-7" data-aos="fade-left">
            <p id="oracle_description_heading">
              First to mind when asked what the cloud is, a majority respond it’s either an actual cloud, the sky, or something related to weather.
            </p>
            <p id="oracle_description_para">
              Cloud computing refers to the delivery of computing resources, including servers, storage, databases, networking, software, and analytics, over the internet. Instead of relying on local servers or personal devices, cloud computing allows users to access and utilize these resources remotely on a pay-as-you-go basis.
            </p>
            <p id="oracle_description_para1">
              <span>Infrastructure as a Service (IaaS): -</span> IaaS provides virtualized computing resources, such as virtual machines, storage, and networks, allowing users to manage and control the underlying infrastructure. Users can scale resources up or down based on their needs, eliminating the need for physical hardware and infrastructure management.
            </p>
            <p id="oracle_description_para1">
              <span>Platform as a Service (PaaS): -</span> PaaS offers a platform for developing, running, and managing applications without the need to worry about infrastructure management. It provides a complete development and deployment environment, including operating systems, databases, and development tools, enabling developers to focus on application development.
            </p>
            <p id="oracle_description_para1">
              <span>Software as a Service (SaaS): -</span> SaaS delivers software applications over the internet, allowing users to access and use them through a web browser or a client application. Users don't have to worry about software installation, maintenance, or infrastructure management, as everything is handled by the service provider.
            </p>

            <h2 className="mt-5 mb-4">Benefits of cloud computing include:</h2>
            <p id="oracle_description_para1">
              <span>Scalability: -</span> Cloud computing enables businesses to easily scale their resources up or down based on demand. Whether it’s increasing server capacity during peak times or reducing resources during periods of low activity, the flexibility of cloud computing allows for efficient resource allocation.
            </p>
            <p id="oracle_description_para1">
              <span>Cost Savings: -</span> With cloud computing, businesses can avoid significant upfront infrastructure costs. Instead, they can opt for a pay-as-you-go model, where they only pay for the resources they consume. This eliminates the need for costly hardware investments, maintenance, and upgrades.
            </p>
            <p id="oracle_description_para1">
              <span>Accessibility and Collaboration: -</span> Cloud computing allows users to access data and applications from anywhere with an internet connection. It promotes collaboration among team members by providing centralized access to shared files and documents, enabling real-time collaboration and remote work.
            </p>
            <p id="oracle_description_para1">
              <span>Disaster Recovery and Business Continuity: -</span> Cloud computing offers robust data backup and disaster recovery capabilities. Data is stored in multiple locations, ensuring its availability even in the event of hardware failures or natural disasters. This enhances business continuity and minimizes downtime.
            </p>
            <p id="oracle_description_para1">
              <span>Security: -</span> Cloud service providers invest heavily in robust security measures to protect customer data. They employ encryption, access controls, and advanced security protocols to ensure data confidentiality and integrity. However, it's important for businesses to implement proper security measures on their end as well.
            </p>
            <p id="oracle_description_para1">
              <span>Innovation and Agility: -</span> Cloud computing enables faster deployment of applications and services, reducing the time to market for businesses. It fosters innovation by providing access to cutting-edge technologies and development tools, allowing businesses to experiment, iterate, and launch new products or services quickly.
            </p>
            <p id="oracle_description_para1">
              <span>Challenges and considerations: -</span> While cloud computing offers numerous benefits, there are also challenges to consider. These include data security concerns, vendor lock-in, potential compliance issues, and reliance on internet connectivity.
            </p>
            <p id="oracle_description_para3">
              Organizations must assess their specific requirements, consider the sensitivity of their data, and choose reliable cloud service providers that align with their needs and security standards.
            </p>
            <p id="oracle_description_para3">
              Overall, cloud computing has revolutionized the way businesses operate and leverage technology. It offers scalability, cost savings, accessibility, and innovation, enabling organizations to focus on their core business objectives while leveraging the power of the cloud.
            </p>
          </div>
        </div>
      </div>

      {/* Oracle FAQs Section */}
      <div className="container mt-5" data-aos="zoom-in-up">
        <p id="faq_heading">Frequently Asked Questions</p>
        <div className="accordion accordion-flush mt-5" id="accordionFlushExample">
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                What is Cloud Computing, and how can it benefit my organization?
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Cloud Computing allows organizations to access and store data and applications over the internet instead of local servers. Benefits include scalability, cost savings, improved collaboration, and enhanced data security.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                What types of cloud services do you offer?
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                We offer various cloud services, including Infrastructure as a Service (IaaS), Platform as a Service (PaaS), Software as a Service (SaaS), and cloud migration services to meet your specific business needs.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                How do you ensure data security in the cloud?
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                We implement robust security measures, including encryption, access controls, regular security audits, and compliance with industry standards, to protect your data in the cloud environment.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                Can you help with migrating our existing systems to the cloud?
              </button>
            </h2>
            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Yes, we provide comprehensive cloud migration services that include planning, data transfer, and system integration to ensure a smooth transition with minimal disruption to your operations.
              </div>
            </div>
          </div>
          <div className="accordion-item pt-3 pb-3">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                What kind of ongoing support do you provide for cloud services?
              </button>
            </h2>
            <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                We offer continuous support, including monitoring, maintenance, performance optimization, and troubleshooting, to ensure your cloud environment remains efficient and secure over time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MainHeader>
  );
};

export default CloudComputingPage;
