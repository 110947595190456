import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/Main-header.css";
import AOS from "aos";
import "aos/dist/aos.css";
import WhatsAppButton from "../components/whatsappicon";

const Navbar = ({ children }) => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            once: false, // Whether the animation should happen only once
        });
    }, []);
    return (
        <>
            <WhatsAppButton></WhatsAppButton>
            <div className="container-fluid p-0 m-0">
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="container">
                        <Link className="navbar-brand d-flex align-items-center" to="/">
                            <img
                                src="/img/GelecekLogo.png"
                                alt="Logo"
                                className="logo"
                                width="90px"
                            />
                        </Link>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div
                            className="collapse navbar-collapse justify-content-between"
                            id="navbarNav"
                        >
                            {/* Centered Navigation Links */}
                            <ul className="navbar-nav mx-auto gap-4" id="navbar-names">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link text-dark active"
                                        aria-current="page"
                                        to="/"
                                    >
                                        Home
                                    </Link>
                                </li>

                                {/* Services Dropdown (Hover to open) */}
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link text-dark"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Services
                                    </a>
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/oracle-ebs-implementation-and-extensions-services"
                                            >
                                                Oracle EBS Implementation and Extensions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/oracle-ebs-technical"
                                            >
                                                Oracle EBS Technical
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/cyber-and-it"
                                            >
                                                Cyber / IT Security
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/mobile-applications-development"
                                            >
                                                Mobile Applications Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/business-intelligence"
                                            >
                                                Business Intelligence
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/identity-access"
                                            >
                                                Identity and Access Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/web-and-software-development"
                                            >
                                                Web & Software Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/project-management"
                                            >
                                                Project Management
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="dropdown-item text-dark"
                                                to="/services/cloud-computing"
                                            >
                                                Cloud Computing
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/products">
                                        Products
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/careers">
                                        Careers
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-dark" to="/contact-us">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>

                            {/* Phone Number and Quote Button Aligned to the End */}
                            <div
                                className="d-flex align-items-center justify-content-center mt-3 mt-lg-0 gap-3"
                                id="navbar-contat-div"
                            >
                                <div
                                    style={{ border: "2px solid black" }}
                                    id="navbar-phone-div"
                                >
                                    <a href="tel:+923345592388" className="phone-number text-dark">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                        </svg>
                                        +92 334 5592388
                                    </a>
                                </div>

                                <Link to="/request-a-demo" className="text-dark" id="navbar-qoute">
                                    Request a Demo
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {children}
            <footer
                className="footer-section"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
            >
                <div className="container">
                    <div className="footer-cta pt-5 pb-5">
                        <div className="row g-4">
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta  d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>
                                            Office No 1017, Alpha Techno Square, National Aerospace & Technology Park (NASTP), Nur Khan Airbase, Old Airport Road, Rawalpindi                    </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-phone"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>+92 334 5592388</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta  d-flex flex-row align-content-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="64"
                                        height="64"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="icon icon-tabler icons-tabler-outline icon-tabler-mail"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
                                        <path d="M3 7l9 6l9 -6" />
                                    </svg>{" "}
                                    <div className="cta-text">
                                        <h4>Mail us</h4>
                                        <span>info@geleceksoln.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-content pt-5 pb-5">
                        <div className="row g-4">
                            <div className="col-xl-4 col-lg-4 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <a href="index.html">
                                            <img
                                                src="/img/GelecekLogo.png"
                                                className="img-fluid"
                                                width={150}
                                                alt="logo"
                                            />
                                        </a>
                                    </div>
                                    <div className="footer-text">
                                        <p>
                                            Welcome to the digital realm where innovation meets
                                            expertise, and every line of code is a brushstroke on the
                                            canvas of tomorrow.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>
                                            <a href="/products">Products</a>
                                        </li>
                                        <li>
                                            <a href="/careers">Careers</a>
                                        </li>
                                        <li>
                                            <a href="/about-us">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Services</h3>
                                    </div>
                                    <ul>
                                        <li>
                                            <a href="/services/web-and-software-development">
                                                Web & Software Development
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/mobile-applications-development">
                                                Mobile Applications Development
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/cyber-and-it">Cyber / IT Security</a>
                                        </li>
                                        <li>
                                            <a href="/services/project-management">
                                                Project Management
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/services/cloud-computing">Cloud Computing</a>
                                        </li>
                                        {/* <li>
                      <a href="/services/business-intelligence">Business Intelligence</a>
                    </li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>Subscribe</h3>
                                    </div>
                                    <div className="footer-text mb-25">
                                        <p>
                                            Don’t miss to subscribe to our new feeds, kindly fill the
                                            form below.
                                        </p>
                                    </div>
                                    <div className="subscribe-form">
                                        <form action="#">
                                            <input type="text" placeholder="Email Address" />
                                            <button>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-send-2"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z" />
                                                    <path d="M6.5 12h14.5" />
                                                </svg>{" "}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 text-center">
                                <div className="copyright-text">
                                    <p>
                                        Copyright &copy; 2024, All Rights Reserved{" "}
                                        <a href="https://geleceksoln.com/">Geleceks Solutions</a>
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-xl-6 col-lg-6 d-none d-lg-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Navbar;
