import React from "react";
import MainHeader from "../components/Main-header";
import "../css/contact.css";

function career() {
  return (
    <>
      <MainHeader>
        <div className="about-section position-relative">
          <video
            autoPlay
            preload="none"
            loop
            muted
            className="background-video position-absolute top-0 start-0 w-100 h-100"
            style={{
              objectFit: "cover",
              zIndex: -1,
              filter: "brightness(80%)",
            }}
          >
            <source src="/video/Main-heading.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div
            className="container text-start text-light d-flex flex-column justify-content-center align-items-start"
            style={{ height: "320px" }}
          >
            <div className="content-box">
              <h1
                className="display-4 mb-4 fw-bold" data-aos="zoom-in-right"
                style={{ letterSpacing: "2px" }}
              >
                Careers
              </h1>
            </div>
          </div>
        </div>
        {/* About Us Section */}

      </MainHeader>
    </>
  );
}
export default career;
