import React from "react";
import MainHeader from "../Main-header";
import Servicecontactform from "./servicecontactform";
import "../../css/services.css";

const OracleForm = () => {
  return (
    <MainHeader>
      <div className="container-fluid p-0 m-0">
        <div className="container pt-5 pb-5">
          <div className="row">
            {/* Left Section */}
            <div className="col-md-7 d-flex flex-column" id="oracle_left_div"  data-aos="fade-right">
              <p id="service_heading">Oracle EBS Technical Services</p>
              <p id="services_para">
                Oracle EBS Technical Services ensure optimal performance and
                reliability for your enterprise systems. We offer seamless
                integration, custom development, and ongoing maintenance to
                support your organization’s operational excellence.
              </p>
              <a href="#" id="custom_quote_btn" className="">
                Custom Quote
              </a>
              <p className="mt-4" id="or_call_us_btn">
                or Call Us:{" "}
                <a href="tel:+923345592388" className="">
                +92 334 5592388
                </a>
              </p>
            </div>

            {/* Right Section */}
            <div className="col-md-5" data-aos="fade-left">
                <Servicecontactform></Servicecontactform>
            </div>
          </div>
        </div>
        <video
        autoPlay
        preload="none"
        poster="/img/other-pages-poster.png"
        loop
        muted
        className="background-video position-absolute top-0 start-0 w-100 h-100"
        style={{
          objectFit: "cover",
          zIndex: -1,
          filter: "brightness(80%)",
        }}
      >
        <source src="/video/Main-heading.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <br></br>
      <br></br>
      <br></br>

        {/* Oracle Description Section Start */}
        <div className="container mt-5">
          <div className="row">
            <div className="col-md-5" id="oracle_description_image_div"  data-aos="fade-right">
              <img src="/img/services/oracle-ebs-img.jpg" alt="" />
            </div>
            <div className="col-md-7" data-aos="fade-left">
              <p id="oracle_description_heading">
                Unlock the Full Potential of Oracle EBS: Technical Expertise You
                Can Trust
              </p>
              <p id="oracle_description_para">
                Oracle E-Business Suite (EBS) Technical Services refer to a
                range of specialized services that focus on the technical
                aspects of Oracle EBS implementation, customization,
                integration, and support. These services are designed to help
                businesses optimize their EBS environment, enhance system
                performance, and maximize the value of their investment in
                Oracle EBS.
              </p>
              <p id="oracle_description_para1">
                <span>EBS Implementation and Customization: -</span> Technical
                experts with deep knowledge of Oracle EBS can assist in
                implementing the system within an organization. They work
                closely with stakeholders to understand business requirements
                and configure EBS modules to align with specific needs.
                Customizations may involve developing extensions, integrating
                with third-party systems, and tailoring EBS functionalities to
                enhance business processes.
              </p>
              <p id="oracle_description_para1">
                <span>EBS Upgrades and Migrations: -</span> Oracle EBS Technical
                Services can provide support during version upgrades or system
                migrations. This involves analyzing the existing EBS
                environment, assessing compatibility, planning the
                upgrade/migration strategy, executing the process, and ensuring
                data integrity throughout the transition. Experts ensure a
                smooth upgrade/migration while minimizing downtime and potential
                disruptions.
              </p>
              <p id="oracle_description_para1">
                <span>EBS Performance Tuning: -</span> Technical specialists
                optimize the performance of Oracle EBS by analyzing system
                components, identifying bottlenecks, and implementing
                performance enhancements. They fine-tune database
                configurations, optimize SQL queries, apply patches and updates,
                and employ caching mechanisms to improve system response times,
                scalability, and overall user experience.
              </p>
              <p id="oracle_description_para1">
                <span>EBS Integration and Middleware: -</span> Oracle EBS
                Technical Services encompass integration with other enterprise
                systems, such as CRM, HRMS, or SCM applications. Experts
                leverage middleware technologies, such as Oracle SOA Suite or
                Oracle Integration Cloud, to establish seamless data exchange
                and process automation between EBS and other systems. This
                integration streamlines business workflows, reduces manual
                effort, and ensures data consistency.
              </p>
              <p id="oracle_description_para1">
                <span>EBS Security and Access Controls: -</span> Technical
                specialists implement robust security measures to safeguard
                sensitive data and protect against unauthorized access. They
                configure user roles and responsibilities, establish granular
                access controls, implement encryption and data masking
                techniques, and conduct security audits to ensure compliance
                with industry standards and regulatory requirements.
              </p>
              <p id="oracle_description_para1">
                <span>EBS System Administration and Support: -</span> Technical
                experts provide ongoing system administration and support
                services for Oracle EBS. This includes managing user accounts,
                monitoring system performance, troubleshooting issues, applying
                patches and updates, and providing timely assistance to address
                technical challenges or incidents. They work closely with IT
                teams to maintain system stability, resolve technical issues
                promptly, and minimize downtime.
              </p>
              <p id="oracle_description_para3">
                Oracle EBS Technical Services enable organizations to leverage
                the full potential of Oracle EBS, ensuring its optimal
                performance, integration with other systems, security, and
                ongoing maintenance. By engaging with experienced technical
                consultants, businesses can streamline their EBS operations,
                enhance productivity, and achieve their desired business
                outcomes.
              </p>
            </div>
          </div>
        </div>
        {/* Oracle Description Section End */}

        {/* Oracle FAQs Section Start */}
        <div className="container mt-5" data-aos="zoom-in">
          <p id="faq_heading">
            Frequently Asked Questions
          </p>
          <div
            className="accordion accordion-flush mt-5"
            id="accordionFlushExample"
          >
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What are Oracle EBS Technical Services?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Oracle EBS Technical Services encompass a range of support
                  offerings, including system integration, custom development,
                  performance tuning, and ongoing maintenance to ensure your
                  Oracle EBS environment operates efficiently.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How can Oracle EBS Technical Services improve system
                  performance?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our technical services optimize database configurations,
                  streamline processes, and implement best practices to enhance
                  the overall performance and reliability of your Oracle EBS
                  applications.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Do you provide support for custom development in Oracle EBS?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Yes, we offer custom development services tailored to your
                  specific business needs, including creating custom reports,
                  workflows, and applications that integrate seamlessly with
                  your existing Oracle EBS environment.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  What is the process for implementing Oracle EBS Technical
                  Services?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  Our implementation process involves assessing your current
                  system, identifying improvement areas, customizing solutions,
                  and providing ongoing support and training to ensure a smooth
                  transition and continued success.
                </div>
              </div>
            </div>
            <div className="accordion-item pt-3 pb-3">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed fw-bold"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  What kind of ongoing support can we expect from your Oracle
                  EBS Technical Services?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  We provide comprehensive ongoing support, including
                  troubleshooting, system upgrades, performance monitoring, and
                  regular maintenance, to ensure your Oracle EBS remains
                  up-to-date and operates smoothly.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Oracle FAQs Section End */}
      </div>
    </MainHeader>
  );
};

export default OracleForm;
